import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="Takk for din bestilling" />
    <div className="hero-container hero-container--support">
        <div className="container">
            <div className="hero-container__wrapper">
                <div className="col wow fadeIn text-center">
                    <h2 className="lead hero-container__subtitle">Takk for din bestilling</h2>
                    <p className="hero-container__desc">Du vil høre fra oss veldig snart!</p>
                </div>
            </div>
        </div>
    </div>
    <main className="main" id="main">
        <section className="section section--regular section--contact">
            <div className="container text-center">
                <a href="/" className="button button--primary">Tilbake</a>
            </div>
        </section>
    </main>
  </React.Fragment>
)

export default NotFoundPage
